/******** ROOT *********/
html,
body {
	padding: 0;
	margin: 0;
	background-color: white !important;
}
* {
	box-sizing: border-box;
	font-family: 'quasimoda', sans-serif;
}
a {
	color: var(--primary-color);
	text-decoration: none;
}

:root {
	--primary-color: #273b80;
	--secondary-color: #f7964f;
	--secondary-color-light: rgba(247, 150, 79, 0.1);
}

/**** GENERAL ****/
.ql-editor {
	min-height: 300px;
	font-family: 'quasimoda', sans-serif;
}
.img_fluid {
	max-width: 100%;
	height: auto;
}
.cursor_pointer {
	cursor: pointer;
}
.custom_table .MuiToolbar-gutters {
	padding-left: 15px !important;
}
.price_table,
.disease_scanner_table {
	border-collapse: collapse;
	width: 100%;
}
.price_table th,
.price_table td,
.disease_scanner_table th,
.disease_scanner_table td {
	border: 1px solid rgba(0, 0, 0, 0.1);
	padding: 10px;
	text-align: left;
	font-family: 'quasimoda, sans-serif';
}
.disease_scanner fieldset {
	border: none !important;
}
.disease_scanner .MuiPaper-elevation1 {
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2) !important;
}

.pdf {
	position: relative;
	line-height: 30px;
	font-family: unset;
	width: 210mm;
	height: 297mm;
	outline: 1px solid rgba(0, 0, 0, 0.2);
	padding: 0.8cm;
	font-feature-settings: 'liga' 0;
	font-variant-ligatures: none;
	letter-spacing: 1px;
	font-kerning: normal;
	text-rendering: optimizeLegibility;
	background-color: white;
	color: black;
}
.pdf header {
	display: flex;
	justify-content: space-between;
	border-bottom: 2px solid black;
}
.pdf-header__logo {
	height: 15%;
	width: 25%;
}
.pdf-header__text {
	width: 50%;
	display: flex;
	justify-content: end;
	flex-direction: column;
}

.pdf-header__text > div {
	margin-bottom: 20px;
	margin-left: auto;
	display: flex;
	flex-direction: column;
}
.pdf-header__text span {
	line-height: 22px;
}
.pdf-header__text-tollfree {
	/* word-wrap: normal; */
	margin: 10px 0;
	font-weight: bold;
	font-size: 20px;
	color: #bf344e;
}

.farmer {
	margin-top: 10px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	gap: 30px;
}
.farmer-item {
	min-width: 300px;
}

.farmer-item__heading {
	font-weight: bold;
	margin-right: 10px;
}

.presc {
	display: flex;
	flex-direction: column;
	height: 145mm;
}
.presc__heading {
	text-align: center;
	margin: 10px 0;
}

.presc__box {
	border: 1px solid black;
	padding: 10px;
	flex-grow: 1;
}
.presc-list {
}
.presc__box-row {
	display: flex;
	justify-content: space-between;
	gap: 40px;
}

.presc-item span {
	margin-right: 10px;
}

.pdf footer {
	position: absolute;
	bottom: 0;
	left: 0;
}
.pdf footer img {
	width: 100%;
	height: 15%;
}
